import { reducer as toastrReducer } from 'react-redux-toastr';
// import external modules
import { combineReducers } from 'redux';

import customizer from './customizer/';
import defaultReducer from './default';
import indicateurReducer from './indicateur';
import pageReducer from './page';
import moduleReducer from './module';
import rapportReducer from './rapport';
import thematiqueReducer from './thematique';
import menuReducer from './menu';
import utilisateurReducer from './utilisateur';
import userReducer from './user';
import campagneReducer from './campagne';
import documentationReducer from './documentation';
import mediaReducer from './media';
import contactReducer from './contact';
import alertReducer from './alert';
import weblinkReducer from './weblink';
import partenaireReducer from './partenaire';
import actualiteReducer from './actualite';
import applicationReducer from './application';
import projectReducer from './project';
import tableReducer from './table';
import graphReducer from './graph';
import figureReducer from './figure';

const rootReducer = combineReducers({
    toastr: toastrReducer, // <- Mounted at toastr.
    customizer: customizer,
    thematique: thematiqueReducer,
    menu: menuReducer,
    indicateur: indicateurReducer,
    project: projectReducer,
    application: applicationReducer,
    actualite: actualiteReducer,
    partenaire: partenaireReducer,
    weblink: weblinkReducer,
    alert: alertReducer,
    contact: contactReducer,
    media: mediaReducer,
    documentation: documentationReducer,
    campagne: campagneReducer,
    page: pageReducer,
    figure: figureReducer,
    graph: graphReducer,
    table: tableReducer,
    module: moduleReducer,
    default: defaultReducer,
    user: userReducer,
    utilisateur: utilisateurReducer,
    rapport: rapportReducer
});

export default rootReducer;